import _ from "lodash";
import legendProps from "../../config/legend.props";
import labelProps from "../../config/label.props";

const dataLabelDisplayAreaThreshold = 30;
const fontSizeLimitConstant = 150;
const circularDataLabelDisplayAreaThreshold = 245;
const ticksColor = "#000000"; //making the x axis and y axis tick points to be in black color
export const setLegend = (legend) => {
  const options = {
    legend: {
      position: legend.position,
      align: legend.align,
      labels: {
        usePointStyle: true,
        fontColor: legendProps.fontColor,
        fontSize: legendProps.fontSize,
        fontStyle: legendProps.fontStyle,
        fontFamily: legendProps.fontFamily,
        filter: (legendItem) => legendItem.text,
      },
    },
    maintainAspectRatio: false,
  };
  return options;
};

const getTicksY = (ticksColor,yMaxObj) => {
  const ticksY = {
    suggestedMin: 0,
    lineHeight: 2,
    fontColor: ticksColor
  }
  if (yMaxObj.yMax !== 0) {
    const stepSize = yMaxObj.divider === 1 ? 0.1 : yMaxObj.yMax/yMaxObj.divider;
    ticksY.suggestedMax = yMaxObj.isMultiple || yMaxObj.divider === 1 ? yMaxObj.yMax + stepSize : yMaxObj.yMax;
  }
  return ticksY;
}

export const setYAxisScaleAndTitles = (titles, yAxesOptions, yMaxObj) => {
  const yAxesLength = yAxesOptions.length;
  const yAxes = new Array(yAxesLength);
  for (let index = 0; index < yAxesLength; index++) {
    const item = {
      type: "linear",
      scaleLabel: {
        display: true,
        labelString: yAxesOptions[index].title || yAxesOptions[index].id,
        fontColor: labelProps.fontColor,
        fontFamily: labelProps.fontFamily,
        fontSize: labelProps.fontSize,
        lineHeight: 1.5,
        padding: 8,
      },
      position: yAxesOptions[index].position,
      id: yAxesOptions[index].id,
      stacked: !!yAxesOptions[index].stacked,
      gridLines: {
        display: yAxesOptions[index].showGridLines,
        zeroLineWidth: 2,
      },
      ticks: getTicksY(ticksColor,yMaxObj),
    };
    yAxes[index] = item;
  }
  return yAxes;
};

export const setXAxisScaleAndTitles = (
  xAxisTitle,
  labels,
  isStacked,
  showGridLines
) => {
  return [
    {
      gridLines: {
        drawBorder: true,
        display: showGridLines,
      },
      ticks: {
        suggestedMin: 0,
        minRotation: 20,
        fontSize: labels.labelFontSize
          ? labels.labelFontSize
          : labelProps.fontSize,
        fontColor: ticksColor,
      },
      stacked: !!isStacked,
      scaleLabel: {
        display: true,
        labelString: xAxisTitle ? xAxisTitle : "",
        fontColor: labelProps.fontColor,
        fontFamily: labelProps.fontFambily,
        fontSize: labelProps.fontSize,
        lineHeight: 2,
      },
      labels: labels.multiLineLabelData ? labels.multiLineLabelData : labels,
    },
  ];
};

// for multiline labels label should come with | seperator
export const prepareMultiLineLabelData = (labels, chartData) => {
  const multiLineLabelData = labels.map((label) => {
    return label.split("|");
  });
  const maxLinesLabel = Math.max(
    ...multiLineLabelData.map((label) => {
      return label.length;
    })
  );
  const calculatedLabelFontSize = Math.round(
    fontSizeLimitConstant /
      (chartData.data.datasets[0].data.length + maxLinesLabel)
  );
  const labelFontSize =
    calculatedLabelFontSize > 12
      ? 12
      : calculatedLabelFontSize < 6
      ? 6
      : calculatedLabelFontSize;
  return {
    multiLineLabelData,
    labelFontSize,
  };
};

export const getStackedDataSum = (chartData) => {
  const sumIdenticalIndexItem = (data) => {
    const integerDataArray = data.map((innerArray) => {
      return innerArray.map((innerArrayData) => {
        return parseFloat(innerArrayData) || 0;
      });
    });
    return _.map(_.unzip(integerDataArray), _.sum);
  };
  const stackedDataSum = sumIdenticalIndexItem(_.map(chartData, "data"));
  return stackedDataSum;
};

const isDarkColor = (hexColor) => {
  const [red, green, blue] = hexColor
    .slice(1)
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16));
  return red * 0.2126 + green * 0.7152 + blue * 0.0722 <= 127.5;
};

export const stackedDataSumObj = (stackedDataSum) => {
  return {
    id: "sumDataLabel",
    backgroundColor: "transparent",
    borderColor: "transparent",
    pointBackgroundColor: "transparent",
    pointBorderColor: "transparent",
    pointHoverBackgroundColor: "transparent",
    pointHoverBorderColor: "transparent",
    hoverBorderColor: "transparent",
    data: stackedDataSum,
    type: "line",
    yAxisID: "",
  };
};

export const setDataLabel = (showDataLabels, stackedDataSum) => {
  const hideDataLabelObject = {
    datalabels: {
      display: false,
    },
  };
  const showDataLabelObject = {
    datalabels: prepareDataLabels(stackedDataSum),
    datalabelsCircular: prepareCircularChartDatalabels(stackedDataSum),
    outlabelsCircular: prepareCircularChartOutlabels(stackedDataSum),
  };
  return showDataLabels ? showDataLabelObject : hideDataLabelObject;
};

export const prepareDataLabels = (stackedDataSum) => {
  return {
    display: (context) => {
      const currentData = parseFloat(
        context.dataset.data[context.dataIndex] || 0
      );
      return context.dataset.id === "sumDataLabel"
        ? true
        : Math.round(Math.max(...stackedDataSum) / currentData) <=
            dataLabelDisplayAreaThreshold;
    },
    align: (context) => {
      return context.dataset.id === "sumDataLabel" ? "top" : "center";
    },
    anchor: (context) => {
      return context.dataset.id === "sumDataLabel" ? "top" : "center";
    },
    color: (context) => {
      const chartbgColor = context.dataset.pointBackgroundColor;
      return isDarkColor(chartbgColor) && chartbgColor !== "transparent"
        ? "white"
        : "black";
    },
  };
};

export const prepareCircularChartDatalabels = (dataArr) => {
  return {
    display: (context) => {
      const currentData = parseFloat(
        context.dataset.data[context.dataIndex] || 0
      );
      const percentage =
        Math.round(((currentData * 100) / _.sum(dataArr)) * 100) / 100;
      const calculatedLabelLimit =
        (Math.round(context.chart.outerRadius) * percentage) /
        currentData.toString().length;
      return currentData
        ? calculatedLabelLimit >= circularDataLabelDisplayAreaThreshold
        : false;
    },
    align: "center",
    anchor: "center",
    color: (context) => {
      const chartbgColor = context.dataset.backgroundColor[context.dataIndex];
      return isDarkColor(chartbgColor) && chartbgColor !== "transparent"
        ? "white"
        : "black";
    },
    formatter: (value) => {
      const currentData = value || 0;
      return `${currentData}%`;
    },
  };
};

export const prepareCircularChartOutlabels = (dataArr) => {
  return {
    display: (context) => {
      const currentData = parseFloat(
        context.dataset.data[context.dataIndex] || 0
      );
      const percentage =
        Math.round(((currentData * 100) / _.sum(dataArr)) * 100) / 100;
      const calculatedLabelLimit =
        (Math.round(context.chart.outerRadius) * percentage) /
        currentData.toString().length;
      return currentData
        ? !(calculatedLabelLimit >= circularDataLabelDisplayAreaThreshold)
        : false;
    },
    borderWidth: 1,
    lineWidth: 1,
    padding: 0,
    textAlign: "center",
    stretch: 7,
    font: {
      resizable: true,
      minSize: 12,
      maxSize: 16,
    },
    valuePrecision: 1,
    text: (context) => {
      const currentData = parseFloat(
        context.dataset.data[context.dataIndex] || 0
      );
      return `${currentData}%`;
    },
  };
};

export const setLayoutforOutlabels = () => {
  return {
    padding: {
      left: 10,
      right: 10,
      top: 40,
      bottom: 30,
    },
  };
};

export const checkUniDataLineChart = (chartData) => {
  return !Object.keys(chartData).every((key) => chartData[key].length > 1);
};

export const prepareUniDataLineChart = (chartData) => {
  const modifiedChartData = _.cloneDeep(chartData);
  Object.keys(modifiedChartData).forEach((key) => {
    modifiedChartData[key] = [null, ...modifiedChartData[key]];
  });
  return modifiedChartData;
};
